import MobileNavbar from "./MobileNavbar";
import DesktopNavbar from "./DesktopNavbar";
import { useLocation } from "@solidjs/router";

const SiteNameLogo = (props: { startTransition: (arg0: string) => void }) => {
  return (
    <div class="jl-centered">
      <a
        data-defer
        href="/"
        onClick={(event) => {
          event.preventDefault();
          props.startTransition("/");
        }}
        aria-label="Navigate to homepage"
        class="hover:wavy-underline active:wavy-wide"
        style={{ "--offset": "6px" }}
      >
        <h1 class="text-xl p-4 md:p-0 text-center hidden md:block">
          Jonathan Lau
        </h1>
      </a>
    </div>
  );
};
const Navbar = (props: { startTransition: (arg0: string) => void }) => {
  const location = useLocation();
  return (
    <nav
      class="flex justify-center items-start md:w-full ff-sans-serif prose-tracking"
      role="navigation"
      aria-label="Main Navigation"
    >
      <div class="flex flex-col md:flex-row justify-center md:justify-between w-full mx-4 md:mx-4 underline-offset-2">
        <SiteNameLogo startTransition={props.startTransition} />
        <DesktopNavbar
          path={location.pathname}
          startTransition={props.startTransition}
        />
        <MobileNavbar />
      </div>
    </nav>
  );
};

export default Navbar;
