/// <reference types="vinxi/types/client" />
/// <reference types="vite/client" />
import { mount, StartClient } from "@solidjs/start/client";
import defercss from "~/styles/defer.css?url";
import "~/sw";

const startApp = () => <StartClient />;

mount(startApp, document.querySelector("#root")!);

export default startApp;

function handleFirstTab(e: { keyCode: number }) {
  if (e.keyCode === 9) {
    document.body.classList.add("user-is-tabbing");
    window.removeEventListener("keydown", handleFirstTab);
    window.addEventListener("mousedown", handleMouseDownOnce);
  }
}

function handleMouseDownOnce() {
  document.body.classList.remove("user-is-tabbing");
  window.removeEventListener("mousedown", handleMouseDownOnce);
  window.addEventListener("keydown", handleFirstTab);
}

if (typeof window !== "undefined") {
  import("speedlify-score");
  // createSkipTo();
  document.documentElement.removeAttribute("aria-hidden");
  window.addEventListener("keydown", handleFirstTab);
  if (import.meta.env.DEV) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    window.addEventListener("vite:preloadError", (_event): void => {
      window.location.reload();
    });
  }
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = defercss;
  document.head.appendChild(link);
}
