import { children, Component, JSXElement } from "solid-js";
import { TransitionState } from "~/components/shared/Transition/TransitionContext";
import Footer from "~/components/ui/Footer/Footer";
import Navbar from "~/components/ui/Navbar/Navbar";
import { useTransitionContext } from "../components/shared/Transition/TransitionProvider";

interface Props {
  children: JSXElement;
}
const PageLayout: Component<Props> = (props) => {
  const TRANSITION_CLASSES: { [key in TransitionState]: string } = {
    none: "",
    start: "opacity-0 blur-xs brightness-25",
    loading: "opacity-0 blur-2xl brightness-50",
    end: "opacity-10 blur-lg brightness-80",
  };

  const { startTransition, transitionState } = useTransitionContext();
  // TODO: Move to hook
  // const startTransition = useStartTransition();
  // const transitionState = useTransitionState();

  const resolved = children(() => props.children);
  return (
    <>
      <a
        role="navigation"
        href="#skiplink-content"
        class="absolute left-0 -top-2 py-2 px-4 z-50 transform -translate-y-full focus:translate-y-4 focus:translate-x-2 md:focus:translate-x-32 lg:focus:translate-x-2 transition"
        aria-label="Skip to main content"
      >
        Skip to content
      </a>
      <header class="w-full flex flex-col items-center sticky top-0 backdrop-blur z-20 py-1 md:pb-2 jl-start max-w-screen-sm md:max-w-screen-lg lg:w-11/12 lg:max-w-screen-xl xl:max-w-screen-2xl mx-auto">
        <Navbar startTransition={startTransition} />
      </header>
      <div
        class={`jl-centered transition-all duration-[100ms] ease-in-out w-full ${TRANSITION_CLASSES[transitionState() as TransitionState]} `}
      >
        <main class="jl-start max-w-screen-sm md:max-w-screen-lg lg:w-11/12 lg:max-w-screen-xl xl:max-w-screen-2xl mx-auto">
          {resolved()}
        </main>
        <Footer startTransition={startTransition} />
      </div>
    </>
  );
};

// function Page() {
//   const pageContext = usePageContext();
//   return (
//     <>
//       <Dynamic
//         component={pageContext.Page}
//         {...(pageContext.pageProps ?? {})}
//       />
//     </>
//   );
// }

export default PageLayout;
