import { createSignal, onCleanup } from "solid-js";
import MenuButton from "./MenuButton";
import NavOverlay from "./NavOverlay";

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = createSignal(false);

  // Function to open the menu
  const openMenu = () => {
    document.body.classList.add("no-scroll");
    setIsOpen(true);
  };

  // Function to close the menu
  const closeMenu = () => {
    document.body.classList.remove("no-scroll");
    setIsOpen(false);
  };
  if (typeof window !== "undefined") {
    onCleanup(() => {
      document.body.classList.remove("no-scroll");
    });
  }
  return (
    <div class="block md:hidden relative m-auto py-2">
      <MenuButton onClick={openMenu} isOpen={isOpen()} />
      <NavOverlay isOpen={isOpen()} onClose={closeMenu} />
    </div>
  );
};
export default MobileNavbar;
