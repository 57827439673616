import TransitionContext, { useProviderValue } from "./TransitionContext";
import { JSXElement, useContext } from "solid-js";
export const TransitionProvider = (props: {
  children: number | boolean | Node | JSXElement | null | undefined;
}) => {
  const context = useProviderValue();
  const { startTransition, nextPath, transitionState } = context || {
    startTransition: () => {},
    nextPath: () => "",
    transitionState: () => "none",
  };
  return (
    <TransitionContext.Provider
      value={{ startTransition, nextPath, transitionState }}
    >
      {props.children}
    </TransitionContext.Provider>
  );
};

export function useTransitionContext() {
  const context = useContext(TransitionContext);
  if (context === undefined) {
    throw new Error(
      `useTransitionContext must be used within a TransitionProvider`,
    );
  }
  return context;
}

export function useTransitionState() {
  return useTransitionContext().transitionState;
}
export function useStartTransition() {
  return () => useTransitionContext().startTransition;
}

export default TransitionProvider;
