import { useNavigate } from "@solidjs/router";
import { Component, createSignal } from "solid-js";

type Error = {
  message: string;
};
type Props = {
  error: Error;
};

export const ErrorToast: Component<Props> = (props: Props) => {
  const navigate = useNavigate();
  // eslint-disable-next-line solid/reactivity
  const handleReset = () => navigate("/");
  // eslint-disable-next-line solid/reactivity
  const [error] = createSignal(props.error);

  return (
    <>
      <button type="button" onClick={handleReset}>
        Retry
      </button>
      <p class="text-danger-50">{error()?.message}</p>
    </>
  );
};
