import { externalpaths, sitepaths, socialpaths } from "~/configs/paths";
import { For } from "solid-js";
import { getEncodedPath } from "~/utils/helpers";

function FooterDesktop(props: { startTransition: (arg0: string) => void }) {
  return (
    <div class="flex flex-wrap justify-between xl:grid xl:grid-cols-3 gap-8 text-left items-end place-items-end">
      <div class="cols-span-1">
        <h3 class="text-base">Navigation</h3>
        <ul role="list" class="mt-6 flow justify-center gap-4 text-left">
          <For each={Object.values(sitepaths)}>
            {(link) => {
              const href = getEncodedPath(link);
              return (
                <li class="mb-4 lg:mb-2">
                  <a
                    class="text-nowrap text-sm hover:underline whitespace-nowrap"
                    href={link.path}
                    onClick={(event) => {
                      event.preventDefault();
                      props.startTransition(href);
                    }}
                  >
                    <span class="underline-offset-2">{`${link.title}`}</span>
                  </a>
                </li>
              );
            }}
          </For>
        </ul>
      </div>
      <div class="cols-span-1">
        <h3 class="text-base">Social Links</h3>
        <ul role="list" class="mt-6 flow justify-center gap-4">
          <For each={Object.values(socialpaths)}>
            {(link) => {
              const href = getEncodedPath(link);
              return (
                <li class="mb-4 lg:mb-2">
                  <a
                    class="text-nowrap text-sm hover:underline whitespace-nowrap"
                    href={href}
                    onClick={(event) => {
                      event.preventDefault();
                      props.startTransition(href);
                    }}
                  >
                    <span class="underline-offset-2">{`${link.title}`}</span>
                  </a>
                </li>
              );
            }}
          </For>
        </ul>
      </div>
      <div class="cols-span-1">
        <h3 class="text-base">Updates</h3>
        <ul role="list" class="mt-6 flow justify-center gap-4 text3">
          <For each={Object.values(externalpaths)}>
            {(link) => {
              const href = getEncodedPath(link);
              return (
                <li class="mb-4 lg:mb-2">
                  <a
                    class="text-nowrap hover:underline  whitespace-nowrap"
                    href={href}
                    onClick={(event) => {
                      event.preventDefault();
                      props.startTransition(href);
                    }}
                  >
                    <span class="underline-offset-2 text-sm">{`${link.title}`}</span>
                  </a>
                </li>
              );
            }}
          </For>
        </ul>
      </div>
    </div>
  );
}

export default FooterDesktop;
