/// <reference lib="webworker" />

export default async function loadCacheStorage() {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/_build/sw.js",
        { scope: "/_build/" },
      );

      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(
          "Service Worker registration successful with scope:",
          registration.scope,
        );
      }
    } catch (err) {
      if (import.meta.env.DEV) {
        console.error("Service Worker registration failed:", err);
      }
    }
  }
}
if (!import.meta.env.DEV) {
  loadCacheStorage();
}
