import { clientOnly } from "@solidjs/start";

const Speedlify = clientOnly(() => import("~/components/ui/Speedlify"));

function Accessibility() {
  return (
    <fieldset>
      {/* <legend class="md:whitespace-nowrap w-34 text-center text-sm leading-5"> */}
      <legend class="sr-only">Site Web Vitals</legend>
      <div class="my-auto">
        <div class="flex justify-start items-center h-24 gap-0 m-0 leading-10">
          <Speedlify circleColor="var(--vitals)" />
        </div>
      </div>
    </fieldset>
  );
}

export default Accessibility;
