import { Portal } from "solid-js/web";
import MobileBackButton from "./MobileBackButton";
import MobileNavItems from "./MobileNavItems";

const NavOverlay = (props: {
  isOpen: boolean;
  onClose: (s: boolean) => void;
}) => {
  return (
    <Portal mount={document.querySelector("#nav-overlay")!}>
      {props.isOpen && (
        <div class="fixed top-0 right-0 w-full max-w-full surface2/80 backdrop-blur-md transform translate-y-0 transition-transform duration-200 ease-in-out z-50 min-h-full overflow-hidden">
          <div class="jl-start">
            <div class="w-full h-full flex flex-col justify-between items-start py-8 px-4">
              <div class="flex justify-center items-center jl-centered mx-auto">
                <MobileBackButton onClick={() => props.onClose(false)} />
              </div>
              <MobileNavItems onSelect={() => props.onClose(false)} />
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};

export default NavOverlay;
