import { createSignal } from "solid-js";

const MenuButton = (props: { isOpen: boolean; onClick: () => void }) => {
  const [show, setShow] = createSignal(false);

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  return (
    <div class={`${props.isOpen ? "hidden" : "block"} relative`}>
      <div
        onClick={() => props.onClick()}
        // borderStyle="none"
        class="menu-button w-16 h-16 m-2 bg-transparent z-2 cursor-highlight-pointer-dark"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div class="relative">
          {/* Doodle border start */}
          <svg
            display="none"
            aria-hidden="true"
            style={{
              transform: "rotate(90deg)",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            class="overflow-hidden relative"
          >
            <path
              class={`fill-current ${
                show() ? "text-primary-100" : "text-neutral-50"
              }`}
              fill-rule="evenodd"
              d="M23.27 8.36a11.878 11.878 0 0 0-2.49-4.365 11.88 11.88 0 0 0-4.07-2.945 12.26 12.26 0 0 0-2.6-.82 12.43 12.43 0 0 0-8.39 2 11.6 11.6 0 0 0-4.56 4.86 14.37 14.37 0 0 0-1 3.68 16 16 0 0 0-.11 3.81 9.37 9.37 0 0 0 1.43 4 13.55 13.55 0 0 0 2.77 3.11 9.29 9.29 0 0 0 4.27 2c1.52.28 3.073.34 4.61.18a14.92 14.92 0 0 0 6.51-2.11 8.6 8.6 0 0 0 3.14-3.44 13.35 13.35 0 0 0 .49-9.96Zm-1.43 9.43a7.94 7.94 0 0 1-3.7 3.47 14.42 14.42 0 0 1-5.09 1.4c-1.43.147-2.874.093-4.29-.16a9.93 9.93 0 0 1-3.634-1.602A9.93 9.93 0 0 1 2.41 18a8.41 8.41 0 0 1-1.33-3.5 14.27 14.27 0 0 1 0-3.57A13.39 13.39 0 0 1 2 7.43a10.77 10.77 0 0 1 4.11-4.58A12.71 12.71 0 0 1 12 .73a.33.33 0 0 0 .14.116.33.33 0 0 0 .18.024A5.41 5.41 0 0 1 14 .93a11.6 11.6 0 0 1 2.43.82 9.15 9.15 0 0 1 1.9 1.1 11.8 11.8 0 0 1 4 5.77 12.26 12.26 0 0 1-.49 9.17Z"
              clip-rule="evenodd"
            />
            {/* Menu icon start */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="-8 -10 52 52"
              class="fill-current text-neutral-50"
            >
              <g
                class="fill-current text-neutral-50"
                fill-rule="evenodd"
                clip-path="url(#b)"
                clip-rule="evenodd"
              >
                <path d="M13.52 23.68a3.13 3.13 0 0 0 1.6-2 2.86 2.86 0 0 0-.51-2.56A3.49 3.49 0 0 0 10.82 18c-1.07.41-1.93 1.48-1.74 3.39a.39.39 0 0 0 .77-.05c0-1.35.54-2.1 1.31-2.33a2.32 2.32 0 0 1 2.44.99 1.73 1.73 0 0 1 .31 1.46 2.1 2.1 0 0 1-.9 1.34 2.2 2.2 0 0 1-1.42.33 2.61 2.61 0 0 1-1.4-.54.336.336 0 0 0-.225-.122.331.331 0 0 0-.13.012.333.333 0 0 0-.237.285.334.334 0 0 0 .011.13.334.334 0 0 0 .061.115 3.41 3.41 0 0 0 1.79 1 3.19 3.19 0 0 0 2.06-.33ZM15 12.38a2.86 2.86 0 0 0-.51-2.56 3.3 3.3 0 0 0-1.622-1.042 3.3 3.3 0 0 0-1.928.042 2.82 2.82 0 0 0-1.92 3 .39.39 0 0 0 .132.235.39.39 0 0 0 .253.093.39.39 0 0 0 .385-.328A1.94 1.94 0 0 1 11.3 9.8a2.11 2.11 0 0 1 2.23.81 1.74 1.74 0 0 1 .3 1.47 1.998 1.998 0 0 1-.9 1.32 2.11 2.11 0 0 1-1.41.34 2.57 2.57 0 0 1-1.4-.54.334.334 0 0 0-.225-.122.334.334 0 0 0-.245.072.334.334 0 0 0-.05.47 3.36 3.36 0 0 0 1.78 1 3.21 3.21 0 0 0 2.07-.25A3.09 3.09 0 0 0 15 12.38Zm0-8.67a2.86 2.86 0 0 0-.5-2.56 3.23 3.23 0 0 0-4-.79 2.89 2.89 0 0 0-1.6 3.51.4.4 0 0 0 .182.227.4.4 0 0 0 .288.043.37.37 0 0 0 .134-.062.37.37 0 0 0 .147-.25.37.37 0 0 0-.011-.148A2 2 0 0 1 11 1.34a2.08 2.08 0 0 1 2.5.6 1.75 1.75 0 0 1 .32 1.47 2.11 2.11 0 0 1-.9 1.35 2.14 2.14 0 0 1-1.19.35 2.87 2.87 0 0 1-1.29-.32.34.34 0 0 0-.246-.02.34.34 0 0 0-.195.15.34.34 0 0 0-.042.242.34.34 0 0 0 .133.208 3.68 3.68 0 0 0 1.58.57 3 3 0 0 0 1.73-.3 3.07 3.07 0 0 0 1.006-.794A3.07 3.07 0 0 0 15 3.71Z" />
              </g>
              <defs>
                <clipPath id="b">
                  <path
                    class="fill-current text-neutral-50"
                    d="M24 0v24H0V0z"
                  />
                </clipPath>
              </defs>
            </svg>
          </svg>
          {/* Doodle border end */}
          {/* Menu icon end */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default MenuButton;
