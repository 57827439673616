import { Link, Meta } from "@solidjs/meta";
import { For } from "solid-js";
import metadata from "~/data/metadata.json";
import "~/styles/base.css"; // css module imports are compiled into client-*.css bundle
import cursorCss from "~/styles/cursors.css?url";
import utilities from "~/styles/utilities.css?url";
import blocks from "~/styles/blocks.css?url";
import customCursor from "@assets/svg/cursor.svg?url";
import customCursorHighlight from "@assets/svg/cursor-highlight-dark.svg?url";
import { HttpHeader } from "@solidjs/start";

export default function MetaTags() {
  return (
    <>
      {/* Meta data */}
      <Meta
        http-equiv="X-UA-Compatible"
        content={metadata["X-UA-Compatible"]}
      />
      <Meta name="application-name" content={metadata.applicationName} />
      <Meta name="theme-color" content={metadata.themeColor} />
      <Meta name="referrer" content={metadata.referrer} />
      {/* Apple Mobile Web App Capable */}
      <Meta name="apple-mobile-web-app-capable" content="yes" />
      <Meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />

      {/* Open Graph data */}
      <Meta property="og:locale" content={metadata.og.locale} />
      <Meta property="og:title" content={metadata.og.title} />
      <Meta property="og:type" content={metadata.og.type} />
      <Meta property="og:description" content={metadata.og.description} />
      <Meta property="og:image" content={metadata.og.image} />

      {/* Robots */}
      <Meta name="robots" content={metadata.robots} />

      {/* Assets data */}
      <>
        {/* <Link rel="preload" href={serif} as="font" /> */}
        {/* <Link
          rel="preload"
          href={serif}
          as="font"
          type="font/woff2"
          crossOrigin=""
        /> */}

        <Link rel="preload" href={customCursor} as="image" />
        <Link rel="preload" href={customCursorHighlight} as="image" />
        <For each={metadata.preconnect}>
          {(src: string) => <Link rel="preconnect" href={src} />}
        </For>
        <Link
          rel="apple-touch-icon"
          sizes="180x180"
          href={metadata.appleTouchIcon}
        />

        <Link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={metadata.favicon16}
        />
        <Link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={metadata.favicon32}
        />
        <Link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={metadata.androidChrome192}
        />
        <Link rel="mask-icon" href={metadata.safariPinnedTab} color="#e3e3e8" />
        {/* <Link
          rel="preload"
          href={sansSerifFont}
          as="font"
          type="font/woff2"
          crossOrigin=""
          />

          <Link
          rel="preload"
          href={monoFont}
          as="font"
          type="font/woff2"
          crossOrigin=""
        /> */}
        <Link rel="manifest" href={metadata.manifest} />
        <Link rel="shortcut icon" href={metadata.shortcutIcon} />
        <Link rel="preload" href={cursorCss} as="style" />
        <Link rel="preload" href={utilities} as="style" />
        <Link rel="preload" href={blocks} as="style" />
        {/* <link
          rel="preload"
          href={defer}
          as="style"
          onLoad={(event) =>
            ((event.target as HTMLLinkElement).rel = "stylesheet")
          }
        /> */}
        <Link rel="stylesheet" href={cursorCss} />
        <Link rel="stylesheet" href={utilities} />
        <Link rel="stylesheet" href={blocks} />
      </>
      {/* Service Worker */}
      <HttpHeader name="Service-Worker-Allowed" value="/" />
    </>
  );
}
