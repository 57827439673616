import { For, splitProps, useContext } from "solid-js";
import TransitionContext from "~/components/shared/Transition/TransitionContext";
import { sitepaths, socialpaths } from "~/configs/paths";
import { getEncodedPath } from "~/utils/helpers";

// @ts-expect-error ignore
const MobileNavItems = (props) => {
  const [local, _others] = splitProps(props, [
    "children",
    "selectedLink",
    "onSelect",
    "colorMode",
  ]);
  const context = useContext(TransitionContext);
  if (!context) {
    throw new Error(
      "SiteAssetsContext is undefined. This usually means you are trying to use context outside of the provider. Make sure your component is wrapped with the SiteAssetsContext.Provider.",
    );
  }

  const { startTransition } = context;

  return (
    <div
      class="jl-stack w-2/4 h-full text-lg"
      onClick={() => local.onSelect()} // Add onClick handler here
    >
      <ul class="flow flow-list">
        <For each={Object.values(sitepaths)}>
          {(link) => {
            const href = link.path;
            let target;
            if (link.title === "Colophon") {
              target = "_self";
            }
            return (
              <li class="mb-4 lg:mb-2">
                <a
                  class="text-nowrap text-sm hover:underline md:whitespace-nowrap"
                  href={href}
                  target={target}
                  onClick={(event) => {
                    if (link.title === "Colophon") {
                      return;
                    }
                    event.preventDefault();
                    startTransition!(href);
                  }}
                >
                  <span class="underline-offset-2">{`${link.title}`}</span>
                </a>
              </li>
            );
          }}
        </For>
      </ul>
      <br />
      <ul role="list" class="mt-6 flow justify-center gap-4">
        <For each={Object.values(socialpaths)}>
          {(link) => {
            const href = getEncodedPath(link);
            return (
              <li class="mb-4 lg:mb-2">
                <a
                  class="text-nowrap text-sm hover:underline whitespace-nowrap"
                  href={href}
                  onClick={(event) => {
                    event.preventDefault();
                    props.startTransition(href);
                  }}
                >
                  <span class="underline-offset-2">{`${link.title}`}</span>
                </a>
              </li>
            );
          }}
        </For>
      </ul>
    </div>
  );
};

export default MobileNavItems;
