import { JSXElement, createSignal, onCleanup, onMount } from "solid-js";
import SiteAssetsContext, { SiteAssetsState } from "./SiteAssetsContext";

interface Props {
  children: JSXElement;
}

interface ToggleElement extends Element {
  disabled: boolean;
}

const DISABLE_WEBFONT_CLASSNAME = "disable-webfonts";

let isToggling = false;
const SiteAssetsProvider = (props: Props) => {
  const [siteAssetsState, setSiteAssetsState] = createSignal<SiteAssetsState>({
    css: true,
    webFonts: true,
  });

  const toggleCSS = (enable: boolean) => {
    const styles = Array.from(document.querySelectorAll("style"));
    for (let j = 0, k = styles.length; j < k; j++) {
      styles[j].disabled = !enable;
    }
    const stylesheets = Array.from(
      document.querySelectorAll('link[rel="stylesheet"]'),
    );
    for (let j = 0, k = stylesheets.length; j < k; j++) {
      (stylesheets[j] as ToggleElement).disabled = !enable;
    }
  };

  const toggleClassname = (enable: boolean, className: string) => {
    document.documentElement.classList.toggle(className, !enable);
  };

  const cssHandler = () => {
    if (isToggling) return;
    isToggling = true;
    const enable = !siteAssetsState().css;
    setSiteAssetsState((state) => ({ ...state, css: enable }));
    toggleCSS(siteAssetsState().css);
    isToggling = false;
  };

  const webfontHandler = () => {
    if (isToggling) return;
    isToggling = true;
    const enable = !siteAssetsState().webFonts;
    setSiteAssetsState((state) => ({ ...state, webFonts: enable }));
    toggleClassname(enable, DISABLE_WEBFONT_CLASSNAME);
    isToggling = false;
  };

  const handleClick = (event: MouseEvent) => {
    const target = event.target as Element;
    const toggle = target.closest(".toggle");
    if (toggle) {
      const wasPressed = toggle.getAttribute("aria-checked") === "true";
      const isPressed = !wasPressed;
      toggle.setAttribute("aria-checked", String(isPressed));

      if (toggle.classList.contains("toggle-css")) {
        toggleCSS(isPressed);
      } else {
        toggleClassname(isPressed, DISABLE_WEBFONT_CLASSNAME);
      }
    }
  };

  onMount(() => {
    document.addEventListener("click", handleClick);

    onCleanup(() => {
      document.removeEventListener("click", handleClick);
    });
  });

  return (
    <SiteAssetsContext.Provider
      value={{
        siteAssetsState,
        setSiteAssetsState,
        cssHandler,
        webfontHandler,
      }}
    >
      {props.children}
    </SiteAssetsContext.Provider>
  );
};

export default SiteAssetsProvider;
