// @refresh reload
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import BaseProvider from "~/layouts/default";
import {
  Suspense,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
} from "solid-js";

import { onCLS, onLCP, onTTFB } from "web-vitals";

/**
 * Progressive Enhancement Steps
 * Global CSS
 * Composition
 * Utilties
 * Blocks
 * Exceptions
 */

// Note: <App> renders on server
export default function App() {
  let observer: PerformanceObserver;

  const [isLayoutRendered, setLayoutRendered] = createSignal(true);
  if (import.meta.env.DEV) {
    const metaurl = import.meta.url;
    const asseturl = import.meta.env.VITE_APP_BASE_URL;

    // eslint-disable-next-line no-console
    console.log("🚀 -------------------------------🚀");
    // eslint-disable-next-line no-console
    console.log("🚀 ⚛︎ App ⚛︎ asseturl:", asseturl);
    // eslint-disable-next-line no-console
    console.log("🚀 ⚛︎ App ⚛︎ metaurl:", metaurl);
    // eslint-disable-next-line no-console
    console.log("🚀 -------------------------------🚀");
  }
  onMount(() => {
    setLayoutRendered(true);
  });

  createEffect(() => {
    if (isLayoutRendered()) {
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
  });

  if (import.meta.env.DEV) {
    onMount(() => {
      // eslint-disable-next-line no-console
      onCLS(console.log, { reportAllChanges: true });
      // eslint-disable-next-line no-console
      // eslint-disable-next-line no-console
      onLCP(console.log, { reportAllChanges: true });
      // eslint-disable-next-line no-console
      onTTFB(console.log, { reportAllChanges: true });

      observer = new PerformanceObserver((entryList) => {
        for (const entry of entryList.getEntries()) {
          // eslint-disable-next-line no-console
          console.log("LCP candidate:", entry.startTime, entry);
        }
      });

      observer.observe({ type: "largest-contentful-paint", buffered: true });
      observer.observe({ type: "layout-shift", buffered: true });
    });

    onCleanup(() => {
      if (observer !== undefined) {
        observer.disconnect();
      }
    });
  }
  return (
    <Router
      root={(props) => (
        <Suspense>
          <BaseProvider>{props.children}</BaseProvider>
        </Suspense>
      )}
    >
      <FileRoutes />
    </Router>
  );
}
