import { Accessor, createContext } from "solid-js";

export interface SiteAssetsState {
  css: boolean;
  webFonts: boolean;
}
export interface SiteAssetsContextValue {
  siteAssetsState: Accessor<SiteAssetsState>;
  setSiteAssetsState: (state: SiteAssetsState) => void;
  webfontHandler: () => void;
  cssHandler: () => void;
}

export const SiteAssetsContext = createContext<SiteAssetsContextValue>();

export default SiteAssetsContext;
