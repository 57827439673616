import { useLocation } from "@solidjs/router";
import { For } from "solid-js";
import { sitepaths } from "~/configs/paths";
import { getEncodedPath } from "~/utils/helpers";
type LinkType = {
  path: string;
  title?: string;
  isExternal?: boolean;
};
const DesktopNavbar = (props: {
  path: string;
  startTransition: (arg0: string) => void;
}) => {
  const location = useLocation();
  const paths = Object.values(sitepaths).slice(0, -1);
  return (
    <ul
      role="list"
      class="hidden md:flex gap-2 md:gap-4 desktop-navbar justify-end items-center"
    >
      <For each={paths}>
        {(link: LinkType) => {
          const href = getEncodedPath(link);
          return (
            <li role="listitem">
              <a
                data-defer
                href={href}
                class="py-1 prefix-slash before:align-text-top text-neutral-50 no-underline align-text-bottom hover:no-underline transition-all ease-in-out"
                onClick={(event) => {
                  event.preventDefault();
                  if (location.pathname === link.path) return;
                  props.startTransition(href);
                }}
              >
                <span
                  class={`md:whitespace-nowrap hover:wavy-underline pb-1 leading-none  hover:pb-3 ${location.pathname === link.path ? "border-b-2 border-solid border-brand-800 hover:border-none" : "pb-1 hover:wavy-underline active:wavy-wide active:wavy-active"}`}
                  style={{ "--offset": "6px" }}
                >
                  {link.title}
                </span>
              </a>
            </li>
          );
        }}
      </For>
    </ul>
  );
};

export default DesktopNavbar;
