import { externalpaths, sitepaths, socialpaths } from "~/configs/paths";
import { For } from "solid-js";
import { getEncodedPath } from "~/utils/helpers";
import { clientOnly } from "@solidjs/start";
const Expandable = clientOnly(() => import("../Accordion/Expandable"));
function FooterMobile(props: { startTransition: (arg0: string) => void }) {
  return (
    <>
      <Expandable id="navigation" label="Navigation" default>
        <For each={Object.values(sitepaths)}>
          {(link) => {
            const href = link.path;
            let target;
            if (link.title === "Colophon") {
              target = "_self";
            }
            return (
              <a
                class="text-nowrap md:whitespace-nowrap w-full"
                href={href}
                target={target}
                onClick={(event) => {
                  if (link.title === "Colophon") {
                    return;
                  }
                  event.preventDefault();
                  props.startTransition(href);
                }}
              >
                {link.title}
              </a>
            );
          }}
        </For>
      </Expandable>

      <Expandable id="socials" label="Social Links">
        <For each={Object.values(socialpaths)}>
          {(link) => {
            const href = getEncodedPath(link);
            return (
              <a
                class="text-nowrap md:whitespace-nowrap w-full"
                href={href}
                onClick={(event) => {
                  event.preventDefault();
                  props.startTransition(href);
                }}
              >
                {link.title}
              </a>
            );
          }}
        </For>
      </Expandable>
      <Expandable id="updates" label="Updates">
        <For each={Object.values(externalpaths)}>
          {(link) => {
            const href = getEncodedPath(link);
            return (
              <a
                class="text-nowrap md:whitespace-nowrap w-full"
                href={href}
                onClick={(event) => {
                  event.preventDefault();
                  props.startTransition(href);
                }}
              >
                {link.title}
              </a>
            );
          }}
        </For>
      </Expandable>
    </>
  );
}

export default FooterMobile;
