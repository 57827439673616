import { MetaProvider } from "@solidjs/meta";
import { ErrorBoundary, JSXElement, Suspense } from "solid-js";
import { ErrorToast } from "~/components/shared/ErrorToast";
import SiteAssetsProvider from "~/components/shared/SiteAssets/SiteAssetsProvider";
import TransitionProvider from "~/components/shared/Transition/TransitionProvider";
import MetaTags from "~/components/ui/Meta";
import { I18nProvider } from "~/locales";
import PageLayout from "./PageLayout";

interface Props {
  children: JSXElement;
}

function BaseProvider(props: Props) {
  return (
    <ErrorBoundary fallback={(err) => <ErrorToast error={err} />}>
      <MetaProvider>
        <I18nProvider>
          <>
            <MetaTags />
            {/* <CSPMetaTag /> */}
          </>
          <Suspense>
            <SiteAssetsProvider>
              <TransitionProvider>
                <PageLayout>{props.children}</PageLayout>
              </TransitionProvider>
            </SiteAssetsProvider>
          </Suspense>
        </I18nProvider>
      </MetaProvider>
    </ErrorBoundary>
  );
}

export default BaseProvider;
