import { clientOnly } from "@solidjs/start";
import FooterMobile from "./FooterMobile";
import FooterDesktop from "./FooterDesktop";
import Accessibility from "../Accessibility";
import { buildDate } from "~/utils/helpers";
import { lazy, Suspense } from "solid-js";
const ThemeSwitcher = clientOnly(
  () => import("~/components/shared/Display/ThemeSwitcher"),
);
const ToggleFeature = lazy(() => import("./ToggleFeature"));

function Footer(props: { startTransition: (arg0: string) => void }) {
  return (
    <footer class="w-full max-w-full lg:mt-20">
      <hr class="l-to-r" />
      <h2 class="sr-only">Footer</h2>

      <div class="jl-start max-w-screen-sm md:max-w-screen-lg lg:w-11/12 lg:max-w-screen-xl xl:max-w-screen-2xl mx-auto">
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-12 lg:gap-8 flow flow-list">
          {/* Start accolades */}
          <div class="lg:col-span-6 relative">
            <div class="w-3/4">
              <ul
                role="list"
                class="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
              >
                <li class="flex justify-between gap-x-6 py-6 items-start flex-col lg:flex-nowrap">
                  <h3 class="whitespace-nowrap px-2 mb-2 ff-sans-serif font-semibold text-base">
                    Display Settings
                  </h3>
                  <div class="flex gap-2 justify-start m-auto w-full h-50 flex-col">
                    <div class="relative flex flex-col lg:flex-row lg:flex-nowrap gap-2 justify-start h-12">
                      <ThemeSwitcher />
                    </div>
                    <div class="grid-cols-2 ml-3 h-16">
                      <Suspense>
                        <ToggleFeature />
                      </Suspense>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* End accolades */}
          {/* Start column links */}
          <div class="lg:mt-0 lg:col-span-6 space-y-4">
            <div class="flex justify-between text-left items-start w-full max-lg:card-content">
              <div class="footer-mobile">
                <FooterMobile startTransition={props.startTransition} />
              </div>
              <div class="footer-desktop">
                <FooterDesktop startTransition={props.startTransition} />
              </div>
            </div>
          </div>
          <Accessibility />
        </div>
        <div class="flex justify-between gap-x-6 items-center flex-wrap lg:flex-nowrap text-sm p-5 lg:p-2">
          <span class="text-sm leading-5">
            &copy; 2024 Jonathan Lau. All rights reserved.
          </span>
          <span class="whitespace-nowrap ff-sans-serif text3">
            Last updated: {buildDate}
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
