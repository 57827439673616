const en = {
  btn_about: "About",
  btn_back: "Go Back",
  btn_go: "GO",
  btn_home: "Home",
  btn_toggle_dark: "Mode",
  btn_toggle_langs: "Change languages",
  courtesy_notice: "Courtesy Notice",
  intro_desc:
    "By drawing on my multidisciplinary expertise and comprehensive understanding of design systems and web development, I enhance user and developer experiences.",
  intro_dynamic_route: "Demo of dynamic route",
  intro_hi: "Hi, {{ name }}!",
  intro_aka: "Also known as",
  intro_whats_your_name: "What's your name?",
  intro_whats_your_email: "What's your email?",
  not_found: "Page not found",
  welcome: "Welcome to Vitesse-Solid",
  not_in_tauri: "This page is only available in Tauri",
  call_rust_methods: "Call Rust methods",
};

export default en;
